import { defineComponent, reactive } from '@vue/composition-api';
export default defineComponent({
    props: {
        photo: {
            type: Object,
            required: true
        }
    },
    setup() {
        const state = reactive({
            view: false
        });
        return {
            state
        };
    }
});
