import { Feature } from 'ol';
import { fromExtent } from 'ol/geom/Polygon';
import { Fill, Stroke, Style, Text } from 'ol/style';
export const drawExtent = (source, extent) => {
    if (!source.getFeatureById('extent')) {
        const feature = new Feature(fromExtent(extent));
        feature.setId('extent');
        source.addFeature(feature);
    }
};
export const markerStyle = (color, icon) => new Style({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    text: new Text({
        fill: new Fill({ color }),
        font: `normal normal 400 32px ${icon.font.name}`,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        text: icon.codepoint ? String.fromCodePoint(`0x${icon.codepoint}`) : icon.name,
        stroke: new Stroke({ color: 'black', width: 1 })
    })
});
export const hoverMarketStyle = (feature) => {
    const style = feature.getProperties();
    const font = style.icon?.font?.name || 'Material Design Icons';
    const icon = style.icon?.codepoint || 'F034E';
    return new Style({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        text: new Text({
            fill: new Fill({ color: 'red' }),
            font: `normal normal 400 32px ${font}`,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            text: String.fromCodePoint(`0x${icon}`),
            stroke: new Stroke({ color: 'black', width: 1 })
        })
    });
};
