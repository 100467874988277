export var JSendStatuses;
(function (JSendStatuses) {
    JSendStatuses["success"] = "success";
    JSendStatuses["fail"] = "fail";
    JSendStatuses["error"] = "error";
})(JSendStatuses || (JSendStatuses = {}));
export var CitizenReportStatus;
(function (CitizenReportStatus) {
    CitizenReportStatus["Created"] = "created";
    CitizenReportStatus["Pending"] = "pending";
    CitizenReportStatus["Assigned"] = "assigned";
    CitizenReportStatus["Rejected"] = "rejected";
    CitizenReportStatus["Resolved"] = "resolved";
    CitizenReportStatus["Closed"] = "closed";
})(CitizenReportStatus || (CitizenReportStatus = {}));
export const citizenReportStatusLabels = {
    [CitizenReportStatus.Created]: 'Creat',
    [CitizenReportStatus.Pending]: 'Netratat',
    [CitizenReportStatus.Assigned]: 'In lucru',
    [CitizenReportStatus.Rejected]: 'Respins',
    [CitizenReportStatus.Resolved]: 'Rezolvat favorabil',
    [CitizenReportStatus.Closed]: 'Rezolvat nefavorabil'
};
export const citizenReportStatusColors = {
    [CitizenReportStatus.Created]: 'blue',
    [CitizenReportStatus.Pending]: 'orange',
    [CitizenReportStatus.Assigned]: 'blue',
    [CitizenReportStatus.Rejected]: 'red',
    [CitizenReportStatus.Resolved]: 'green',
    [CitizenReportStatus.Closed]: 'red'
};
