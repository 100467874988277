import 'ol/ol.css';
import { computed, defineComponent, onMounted, reactive } from '@vue/composition-api';
import { baseMapConfig } from '@/config';
import TileLayer from 'ol/layer/Tile';
import Map from 'ol/Map';
import OSM from 'ol/source/OSM';
import View from 'ol/View';
import { Polygon } from 'ol/geom';
import { citizenReportStatusLabels } from '@/types';
import { transform } from 'ol/proj';
import { dispatchAxiosErrorAlert, formatDate, injectStrict } from '@/lib/misc';
import { AxiosKey, StoreKey } from '@/symbols';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { GeoJSON } from 'ol/format';
import CitizenReportImage from '@/views/user/citizen-reports/CitizenReportImage.vue';
import { Select } from 'ol/interaction';
import { pointerMove } from 'ol/events/condition';
import { createEmpty, extend, scaleFromCenter } from 'ol/extent';
import { identity } from 'rambda';
import { hoverMarketStyle, markerStyle } from '@/lib/map';
const extent = new Polygon(baseMapConfig.extent).transform('EPSG:4326', 'EPSG:3857').getExtent();
const layer = new VectorLayer({ source: new VectorSource() });
const map = new Map({
    layers: [
        new TileLayer({
            source: new OSM()
        }),
        layer
    ],
    view: new View({
        zoom: baseMapConfig.zoom,
        minZoom: baseMapConfig.minZoom,
        maxZoom: baseMapConfig.maxZoom,
        center: transform(baseMapConfig.center, 'EPSG:4326', 'EPSG:3857'),
        extent
    })
});
const citizenReportFields = [
    {
        field: 'categoryName',
        label: 'Categorie'
    },
    {
        field: 'localityName',
        label: 'Localitate'
    },
    {
        field: 'countyName',
        label: 'Judet'
    },
    {
        field: 'description',
        label: 'Descriere'
    },
    {
        field: 'createdAt',
        label: 'Creat la',
        formatter: (v) => formatDate(v, 'dd.LL.yyyy, HH:mm')
    }
].map(field => ({
    ...field,
    formatter: field.formatter || identity
}));
export default defineComponent({
    components: {
        CitizenReportImage
    },
    setup(_, { refs }) {
        const store = injectStrict(StoreKey);
        const axios = injectStrict(AxiosKey);
        const format = new GeoJSON({
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857'
        });
        const state = reactive({
            interactive: false,
            dialog: false,
            loading: computed(() => markers.loading || marker.loading)
        });
        const markers = reactive({
            data: [],
            loading: false,
            error: null
        });
        const marker = reactive({
            data: null,
            loading: false,
            error: null
        });
        const loadMarker = (markerId) => {
            state.interactive = false;
            marker.loading = true;
            return axios.get(`my-citizen-reports/markers/${markerId}`)
                .then((response) => {
                marker.data = response.data.data;
            })
                .catch((err) => dispatchAxiosErrorAlert(store, err))
                .finally(() => {
                marker.loading = false;
                state.interactive = true;
            });
        };
        const loadMarkers = () => {
            markers.loading = true;
            state.interactive = false;
            return axios.get('my-citizen-reports/markers')
                .then((response) => {
                markers.data = response.data.data;
            })
                .catch((err) => dispatchAxiosErrorAlert(store, err))
                .finally(() => {
                markers.loading = false;
                state.interactive = true;
            });
        };
        onMounted(async () => {
            const mapRef = refs.map;
            const select = new Select({
                condition: pointerMove,
                style: hoverMarketStyle
            });
            map.setTarget(mapRef);
            map.addInteraction(select);
            loadMarkers()
                .then(() => {
                const source = layer.getSource();
                if (!source) {
                    return;
                }
                let extent = createEmpty();
                markers.data.forEach(marker => {
                    const feature = format.readFeature(marker.location);
                    feature.setId(marker._id);
                    feature.setProperties({ color: marker.category.color, icon: marker.category.icon });
                    feature.setStyle(markerStyle(marker.category.color, marker.category.icon));
                    source.addFeature(feature);
                    extent = extend(extent, feature.getGeometry()?.getExtent() || createEmpty());
                });
                scaleFromCenter(extent, 1.1);
                map.getView().fit(extent);
                map.on('pointermove', e => {
                    e.map.getTargetElement().style.cursor = map.hasFeatureAtPixel(e.pixel) ? 'pointer' : '';
                });
                map.on('click', e => {
                    map.forEachFeatureAtPixel(e.pixel, feature => {
                        const id = feature.getId();
                        if (id && state.interactive) {
                            loadMarker(id.toString())
                                .then(() => {
                                state.dialog = true;
                            });
                        }
                    });
                });
            });
        });
        return {
            state,
            marker,
            citizenReportFields,
            citizenReportStatusLabels
        };
    }
});
